<template>
  <WizPage v-if="storySettings" :blok="storySettings.content" :urlPath="storySettings.full_slug" />
</template>

<script setup>
import { storeToRefs } from 'pinia'
const { storySettings } = await useStoryDefaultSettings()
const story = useStoryData()

const { storyData } = storeToRefs(story)

if (!storySettings.value) {
  throw createError({
    statusCode: 404,
    message:
      'Es tut uns leid, aber die Seite, die Sie suchen, konnte leider nicht gefunden werden.',
    statusMessage: 'Seite nicht gefunden',
    fatal: true
  })
}

Object.assign(storyData.value, { ...storySettings.value })
</script>
